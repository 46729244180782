import MFRTeam from '../components/MFRTeam/MFRTeam';
import React from 'react';
import Redirect from '../components/Redirect/Redirect';

export const renderAdditionalComponents = (otherArr) => {
  if (otherArr.length !== 0) {
    const otherComponents = otherArr.map((el, i) => {
      if (el.Active && el.strapi_component === 'content.mr-team') {
        return <MFRTeam team={el.Team} />;
      } else if (el.strapi_component === 'navigation.redirect') {
        return <Redirect redirectPath={el.To}></Redirect>;
      } else return null;
    });

    return otherComponents;
  }
};
