import Checklist from '../components/Checklist/Checklist';
import ContentBlock from '../components/ContentBlock/ContentBlock';
import DownloadSlider from '../components/DownloadSlider/DownloadSlider';
import React from 'react';
import Subpage from '../components/Subpage/Subpage';

export const renderComponents = (blockArr) => {
  const content = blockArr.map((el, i) => {
    if (el.Type === 'Content') {
      return <ContentBlock data={el} key={i} />;
    } else if (el.Type === 'Subpage') {
      return <Subpage data={el} key={i}></Subpage>;
    } else if (el.Type === 'Checklist') {
      return <Checklist data={el} key={i} />;
    } else if (el.Type === 'downloadSlider') {
      return <DownloadSlider data={el} key={i} />;
    } else return <h1 key={i}>no content available</h1>;
  });

  return content;
};
