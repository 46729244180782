import Layout from '../containers/layout.js';
import PageHeader from '../components/PageHeader/PageHeader';
import PageHeader2 from '../components/PageHeader/PageHeader2';
import React, { useEffect } from 'react';
import { getBlocksToRender } from '../util/getBlockstoRender';
import { graphql } from 'gatsby';
import { renderAdditionalComponents } from '../util/renderAdditionalComponents';
import { renderComponents } from '../util/renderComponents';

const Page = ({ location, data }) => {
  const { Title, Header, Other, SEO: seoData } = data.strapiPages;
  const blocksToRender = getBlocksToRender(data.strapiPages);
  useEffect(() => {
      
  }, []);

  return (
    <Layout noPadding seoData={seoData} location={location}>
      {Header.Content !== null && Header.Background !== null ? (
        <PageHeader2 title={Title} header={Header} />
      ) : <h1 style={{margin:'112px 50px 40px 50px'}} >{Title}</h1>}
      {renderComponents(blocksToRender)}
      {renderAdditionalComponents(Other)}
    </Layout>
  );
};

export const query = graphql`
  query pageTemplateQuery($id: String!) {
    strapiPages(id: { eq: $id }) {
      Title
      Template
      downloadSlider {
        id
        externalLink
        downloadFile {
          url
        }
        overlayColor
        overlayText
        downloadImage {
          url
        }
        OpenInNewTab
      }
      DownloadSliderPageOrder
      Header {
        Content
        CTA
        CTAButtonText
        CTAButtonLink
        Background {
          url
          mime
          name
          formats {
            medium {
              url
              mime
              name
            }
          }
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
        }
        VideoBackground
      }
      ContentBlocks {
        YoutubeEmbed
        ContentOrder
        CTAButtonText
        CTAButtonLink
        Image {
          url
          mime
          name
          formats {
            small {
              url
              mime
              name
            }
          }
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
        }
        Title
        Content
        Background {
          url
          mime
          name
          formats {
            medium {
              url
              mime
              name
            }
            large {
              url
              mime
              name
            }
          }
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
        }
        Gradient
        PageOrder
        Type
        SectionID
      }
      SubpageBlocks {
        Type
        Background {
          url
          mime
          name
          formats {
            medium {
              url
              mime
              name
            }
            large {
              url
              mime
              name
            }
          }
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
        }
        Gradient
        Header
        PageOrder
        Subpage {
          Image {
            url
          }
          IsPageLink
          SectionLinkID
          PageLink {
            Title
            id
          }
          Text
        }
      }
      ChecklistBlocks {
        Title
        ChecklistItem {
          Text
          BoldText
        }
        Background {
          url
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
        }
        Gradient
        PageOrder
        Type
      }
      SEO {
        Keywords
        MetaDescription
        MetaTitle
        Slug
        PreviewImage {
          url
          width
          height
          formats {
            small {
              url
              width
              name
              height
            }
          }
        }
      }
      Other
    }
    allStrapiTeamMembers {
      nodes {
        id
        Content
        Email
        Name
        Title
        PhoneNo
        MeetingLink
      }
    }
  }
`;

export default Page;
