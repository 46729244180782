// extracted by mini-css-extract-plugin
export var ContentBlockWrapper = "ContentBlock-module--ContentBlockWrapper--2iu_A";
export var Header = "ContentBlock-module--Header--3oIZH";
export var ContentBlock = "ContentBlock-module--ContentBlock--NFB2K";
export var downloadContainer = "ContentBlock-module--downloadContainer--f_kyz";
export var downloadBox = "ContentBlock-module--downloadBox--2E7Ku";
export var BackgroundImage = "ContentBlock-module--BackgroundImage--11BBL";
export var NoBackground = "ContentBlock-module--NoBackground--238s6";
export var ImageBlock = "ContentBlock-module--ImageBlock--3N-Ir";
export var SVG = "ContentBlock-module--SVG--2YNPq";
export var TextBlock = "ContentBlock-module--TextBlock--24w0F";
export var SeparateTitle = "ContentBlock-module--SeparateTitle--2S9TP";