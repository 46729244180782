// extracted by mini-css-extract-plugin
export var container = "DownloadSlider-module--container--293mr";
export var inner = "DownloadSlider-module--inner--1tDt7";
export var overlay = "DownloadSlider-module--overlay--rcol_";
export var overlayBlue = "DownloadSlider-module--overlayBlue--2gxmu";
export var overlayDarkGray = "DownloadSlider-module--overlayDarkGray--1FH1f";
export var overlayMediumGray = "DownloadSlider-module--overlayMediumGray--1QTMu";
export var overlayWhite = "DownloadSlider-module--overlayWhite--2rJu6";
export var overlayNothing = "DownloadSlider-module--overlayNothing--2joLr";
export var arrow = "DownloadSlider-module--arrow--35fXt";
export var arrowLeft = "DownloadSlider-module--arrowLeft--3fkld";
export var arrowRight = "DownloadSlider-module--arrowRight--1dFrY";