import React, { useEffect } from 'react';

import Loading from '../UI/Loading/Loading';
import { navigate } from '@reach/router';

const Redirect = (props) => {
  const { redirectPath } = props;
  useEffect(() => {
    navigate(`${redirectPath}`);
  }, [redirectPath]);
  return (
    <div style={{ marginTop: '6rem' }}>
      <Loading />
    </div>
  );
};

export default Redirect;
