import * as styles from './Checklist.module.css';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Checkmark from '../../images/check-bullet.svg';
import React from 'react';

const Checklist = (props) => {
  const { Background, Gradient, ChecklistItem, Title } = props.data;

  const BannerBackground = Background !== null ? Background.url : null;
  const setGradient = (gradient) => {
    switch (gradient) {
      case 'Blue':
        return 'linear-gradient(rgba(44, 172, 226, 0.8) 0%, rgba(44, 172, 226, 0.7) 100%)';
      case 'Black':
        return 'linear-gradient(rgba(26, 26, 26, .9) 21%, rgba(26, 26, 26, 0.9) 100%)';
      case 'Gray':
        return 'linear-gradient(rgba(153, 153, 153, .85) 0%, rgba(153, 153, 153, 0.8) 100%)';
      default:
        return 'none';
    }
  };

  const backgroundGradient = setGradient(Gradient);

  const bannerBackground = {
    background: `${backgroundGradient}`,
  };
  const checklistClassses = [styles.Checklist];
  if (Gradient === 'None' || Gradient === null) {
    checklistClassses.push(styles.NoBackground);
  }

  if (ChecklistItem.length === 5) {
    checklistClassses.push(styles.Five);
  }
  if (ChecklistItem.length === 7) {
    checklistClassses.push(styles.Seven);
  }
  if (ChecklistItem.length % 3 === 0) {
    checklistClassses.push(styles.Threes);
  }
  return (
    <section>
      <div className={checklistClassses.join(' ')} style={bannerBackground}>
        {Background ? (
          <GatsbyImage
            image={getImage(Background.localFile)}
            className={styles.BackgroundImage}
          />
        ) : null}
        {Title && <h3>{Title}</h3>}
        <ul>
          {ChecklistItem.map((el, i) => {
            return (
              <li key={i}>
                <Checkmark />
                <p>
                  <strong>{el.BoldText && `${el.BoldText} - `}</strong>
                  {el.Text}
                </p>
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
};

export default Checklist;
