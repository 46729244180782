import React, { useRef, useEffect } from 'react';
import * as styles from './DownloadSlider.module.css';

function DownloadSlider(props) {
  let interval;
  let startTime;
  let startX;
  let startLeft;
  let linkto='';
  let openinnewtab = 'false';
  let items = props.data.Items;
  console.log(items);
  
  const innerRef = useRef(null);



  function startDrag(e) {
    //linkto shoule be either the linkto attribute of the srcelement or possibly the linkto attribute of the parent element
    linkto = e.target.attributes['linkto'] ? e.target.attributes['linkto'].nodeValue : e.target.parentElement.attributes['linkto'].nodeValue; 
    openinnewtab = e.target.attributes['openinnewtab'] ? e.target.attributes['openinnewtab'].nodeValue : e.target.parentElement.attributes['openinnewtab'].nodeValue;
    console.log(e.target.attributes);
    e.preventDefault();

    clearInterval(interval);

    startTime = Date.now();

    startX = e.clientX || e.touches[0].clientX;
    startLeft = parseInt(innerRef.current.style.left) || 0;

    document.addEventListener('mousemove', doDrag);
    document.addEventListener('touchmove', doDrag);
    document.addEventListener('mouseup', endDrag);
    document.addEventListener('touchend', endDrag);
  }

  useEffect(() => {
    innerRef.current.addEventListener('mousedown', startDrag);
    innerRef.current.addEventListener('touchstart', startDrag);

    return () => {
      innerRef.current.removeEventListener('mousedown', startDrag);
      innerRef.current.removeEventListener('touchstart', startDrag);
    };
  }, []);

  function doDrag(e) {
    e.preventDefault();

    const x = e.clientX || e.touches[0].clientX;
    const offsetX = x - startX;

    const newLeft = startLeft + offsetX;
    const constrainedLeft = constrainScrollPosition(newLeft);
    innerRef.current.style.left = `${constrainedLeft}px`;
  }

  function inertialScroll(x,fixed) {
    if(interval){
        clearInterval(interval);
    }
    let distance;
    let deltaTime;

    if(!fixed) {
        const deltaX = x - startX;
        deltaTime = Date.now() - startTime;
        distance = deltaX;
    } else {
        distance = x;
        deltaTime = 10;
    }
    const maxdistance = 200;
    const mindistance = -200;
    if(distance > maxdistance) {
        distance = maxdistance;
    } else if(distance < mindistance) {
        distance = mindistance;
    }

    const friction = 0.75;
    const maxDuration = 230;
    interval = null;

    function doInertialScroll() {
      distance *= friction;
      const roundedDistance = Math.round(distance);

      if (Math.abs(roundedDistance) <= 1) {
        clearInterval(interval);
      } else {
        startLeft = parseInt(innerRef.current.style.left) || 0;
        const newLeft = startLeft + roundedDistance;
        const constrainedLeft = constrainScrollPosition(newLeft);
        innerRef.current.style.left = `${constrainedLeft}px`;
      }
    }

    if (deltaTime <= maxDuration) {
      interval = setInterval(doInertialScroll, 30);
    }
  }

  function endDrag(e) {

    document.removeEventListener('mousemove', doDrag);
    document.removeEventListener('touchmove', doDrag);
    document.removeEventListener('mouseup', endDrag);
    document.removeEventListener('touchend', endDrag);

    

    const x = e.clientX || e.changedTouches[0].clientX;


    if(Math.abs(x-startX) < 10) {
        if (linkto!=''){
            if (openinnewtab==='true'){
              window.open(linkto,  '_blank');
            } else {
              window.open(linkto,  '_self' );
            }
        }
    } else {
        console.log("drag");
        inertialScroll(x);
    }

  }

  function constrainScrollPosition(newLeft) {
    const windowWidth = window.innerWidth;
    const innerWidth = innerRef.current.clientWidth;
    if (innerWidth <= windowWidth) {
      return 0;
    } else {
      const minLeft = windowWidth - innerWidth;
      return Math.min(Math.max(newLeft, minLeft), 0);
    }
  }



  return (
    <div className={styles.container}>
        {/* bind inertial scroll to left and right arrows as buttons with x being positive 30 and negative 30 respectively */}
      <img 
        src="https://d2t1ydnqrsm3ke.cloudfront.net/downloadslider/left-arrow-shadow.png" 
        className={`${styles.arrow} ${styles.arrowLeft}`} 
        onClick={() => inertialScroll(120, true)} />
      <img 
        src="https://d2t1ydnqrsm3ke.cloudfront.net/downloadslider/right-arrow-shadow.png" 
        className={`${styles.arrow} ${styles.arrowRight}`} 
        onClick={() => inertialScroll(-120, true)} 
        />
      <div className={styles.inner} ref={innerRef}>
        {items.map((item, index) => (
            <div OpenInNewTab={item.OpenInNewTab?'true':'false'} LinkTo={item.externalLink?item.externalLink:item.downloadFile.url} className={`${styles.overlay} ${styles[`overlay${item.overlayColor}`]}`}><p>{item.overlayText}</p><img src={item.downloadImage.url} /></div>
        ))}
        {items.map((item, index) => (
            <div OpenInNewTab={item.OpenInNewTab?'true':'false'}  LinkTo={item.externalLink?item.externalLink:item.downloadFile.url} className={`${styles.overlay} ${styles[`overlay${item.overlayColor}`]}`}><p>{item.overlayText}</p><img src={item.downloadImage.url} /></div>
        ))}
        {items.map((item, index) => (
            <div OpenInNewTab={item.OpenInNewTab?'true':'false'}  LinkTo={item.externalLink?item.externalLink:item.downloadFile.url} className={`${styles.overlay} ${styles[`overlay${item.overlayColor}`]}`}><p>{item.overlayText}</p><img src={item.downloadImage.url} /></div>
        ))}
        {items.map((item, index) => (
            <div OpenInNewTab={item.OpenInNewTab?'true':'false'}  LinkTo={item.externalLink?item.externalLink:item.downloadFile.url} className={`${styles.overlay} ${styles[`overlay${item.overlayColor}`]}`}><p>{item.overlayText}</p><img src={item.downloadImage.url} /></div>
        ))}
       </div>
    </div>
  );
}

export default DownloadSlider;