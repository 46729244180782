import * as styles from './Subpage.module.css';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link, graphql, useStaticQuery } from 'gatsby';

import React from 'react';

const Subpage = (props) => {
  const subpages = props.data.Subpage;
  const { Header, Background, Gradient } = props.data;

  const pageQuery = useStaticQuery(graphql`
    query allPagesQuery {
      allSitePage {
        nodes {
          context {
            id
          }
          path
        }
      }
    }
  `);

  const allPages = pageQuery.allSitePage.nodes;
  
  //SMOOTH SCROLL TO ANCHOR
  const anchorClickHandler = event => {
    event.preventDefault();
    let hash = event.currentTarget.href.split('#')[1];
    let targetEle = document.getElementById(hash);
    let pos = targetEle.style.position;
    let top = targetEle.style.top;
    targetEle.style.position = 'relative';
    targetEle.style.top = '-60px';
    targetEle.scrollIntoView({behavior: 'smooth', block: 'start'});
    targetEle.style.top = top;
    targetEle.style.position = pos;
  };


  const setGradient = (gradient) => {
    switch (gradient) {
      case 'Blue':
        return 'linear-gradient(rgba(44, 172, 226, 0.8) 0%, rgba(44, 172, 226, 0.7) 100%)';
      case 'Black':
        return 'linear-gradient(rgba(26, 26, 26, .9) 21%, rgba(26, 26, 26, 0.9) 100%)';
      case 'Gray':
        return 'linear-gradient(rgba(153, 153, 153, .85) 0%, rgba(153, 153, 153, 0.8) 100%)';
      default:
        return 'none';
    }
  };

  const backgroundGradient = setGradient(Gradient);

  const bannerBackground = {
    background: backgroundGradient,
  };

  const BlockClasses = [styles.IconBlockWrapper];
  if (Gradient === 'None' || Gradient === null) {
    BlockClasses.push(styles.NoBackground);
  }
  if (subpages.length === 5) {
    BlockClasses.push(styles.Five);
  }
  if (subpages.length === 7) {
    BlockClasses.push(styles.Seven);
  }
  return (
    <section>
      <div className={BlockClasses.join(' ')} style={bannerBackground}>
        {Background ? (
          <GatsbyImage
            image={getImage(Background.localFile)}
            className={styles.BackgroundImage}
          />
        ) : null}
        <h3>{Header}</h3>
        <div className={styles.IconBlock}>
          {subpages &&
            subpages.map((el, i) => {
              // check if Subpage items are links or not
              let id, url;
              let clickHandle=()=>{};
              if (el.IsPageLink) {
                id = el.PageLink.id;
                if (id !== undefined) {
                  url = allPages.filter(
                    (el) => el.context.id === `Pages_${id}`
                  )[0].path;
                } else {
                  url = null;
                }
              } else if (el.SectionLinkID !== null) {
                clickHandle=anchorClickHandler;
                url = `${el.SectionLinkID}`;
              } else {
                url = null;
              }
              const cardClasses = [styles.IconCard];
              if (subpages.length % 2 !== 0 && subpages.length!==2) {
                cardClasses.push(styles.Odd);
              }
              return (
                <Link className={cardClasses.join(' ')} onClick={(e)=>clickHandle(e)} key={i} to={url}>
                  <div className={`${styles.IconImage} ${subpages.length===2?styles.IconImageTwoCol:''}`}>
                    {el.Image && <img src={el.Image.url} />}
                  </div>
                  <div className={styles.Content}>
                    <h4>{el.Text}</h4>
                  </div>
                </Link>
              );
            })}
        </div>
      </div>
    </section>
  );
};

export default Subpage;
