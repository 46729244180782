import * as styles from './ContentBlock.module.css';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import DownloadSlider from '../DownloadSlider/DownloadSlider';

import MarkdownIt from 'markdown-it';
import React from 'react';

import YoutubeEmbedIframe from '../YoutubeEmbed/YoutubeEmbed';

import CTAbutton from '../CTAbutton/CTAbutton';

import { setTrue } from '../../state/slices';
import { useDispatch } from 'react-redux';

import HubspotForm from 'react-hubspot-form';

const decodeEntities = encodedString => {
  var translate_re = /&(nbsp|amp|quot|lt|gt);/g;
  var translate = {
      "nbsp":" ",
      "amp" : "&",
      "quot": "\"",
      "lt"  : "<",
      "gt"  : ">"
  };
  return (encodedString+'').replace(translate_re, function(match, entity) {
      return translate[entity];
  }).replace(/&#(\d+);/gi, function(match, numStr) {
      var num = parseInt(numStr, 10);
      return String.fromCharCode(num);
  });
}

const ContentBlock = (props) => {
  const {
    Content,
    ContentOrder,
    Image,
    Title,
    Background,
    Gradient,
    SectionID,
    YoutubeEmbed,
    CTAButtonLink,
    CTAButtonText
  } = props.data;

  const dispatch = useDispatch();

  const clickHandler = (e) => {
    e.preventDefault();
    switch (e.target.attributes['js-name'].nodeValue.split(':')[1]){
      case 'display-alert':
        alert('this is a display alert');
        break;
      case 'contact-form':
        dispatch(setTrue());
        break;
      default:
        console.warn('Click Handler not defined in Strapi define using javascript:click-handler-name')
    }
  };

  const md = new MarkdownIt();
  const content = Content ? decodeEntities(md.render(Content)) : decodeEntities(props.content);

  const orderStyle = {
    flexDirection:
      ContentOrder === 'ImageFirst' || ContentOrder === 'TextThenTitle'
        ? 'initial'
        : 'row-reverse',
  };

  const setGradient = (gradient) => {
    switch (gradient) {
      case 'Blue':
        return 'linear-gradient(rgba(44, 172, 226, 0.8) 0%, rgba(44, 172, 226, 0.7) 100%)';
      case 'Black':
        return 'linear-gradient(rgba(26, 26, 26, 1) 21%, rgba(26, 26, 26, 0.8) 100%)';
      case 'Gray':
        return 'linear-gradient(rgba(153, 153, 153, .85) 0%, rgba(153, 153, 153, .8) 100%)';
      default:
        return '';
    }
  };

  const getMime = (img) => {
    if (img !== null && img !== undefined) {
      if (img.mime.includes('svg')) {
        return 'svg';
      }
      return 'img';
    }
    return null;
  };

  const img = {
    url: Image ? Image.url : null,
    smallUrl:
      Image && Image.formats !== null && Image.formats.small !== null
        ? Image.formats.small.url
        : null,
    mime: Image ? getMime(Image) : null,
    name: Image ? Image.name : null,
  };

  const backgroundGradient = setGradient(Gradient);

  const bannerBackground = {
    background: backgroundGradient,
  };

  const ContentBlockClasses = [styles.ContentBlockWrapper];
  if (Gradient === 'None' || Gradient === null) {
    ContentBlockClasses.push(styles.NoBackground);
  }

  const ContentWithImage = () => {
    return (
      <>
        <div className={styles.ImageBlock}>
          {!!YoutubeEmbed ? (
            <YoutubeEmbedIframe embedId={YoutubeEmbed} />
          ) : img.url !== null ? (
            <img
              src={img.smallUrl ? img.smallUrl : img.url}
              alt={img.name}
              className={img.mime === 'svg' ? styles.SVG : null}
            />
          ) : null}
        </div>
        <div className={styles.TextBlock}>
          {Title && <h2>{Title}</h2>}
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
          {!!CTAButtonText && !!CTAButtonLink ? (
            <CTAbutton url={CTAButtonLink} style={{cursor:'pointer'}} classNames={['background_'+Gradient]} message={CTAButtonText} handler={(e) => clickHandler(e)} />
          ) : null}
          
        </div>
      </>
    );
  };

  const ContentNoImage = () => {
    return (
      <>
        <div className={styles.TextBlock} style={{ minHeight: '200px' }}>
          {Title && <h2 className={styles.SeparateTitle}>{Title}</h2>}
        </div>
        <div className={styles.TextBlock}>
        
        {Content === 'INSERT_HUBSPOT_FORM' ? <HubspotForm
          portalId='2687165'
          formId='efb0c181-4955-45a5-937e-32276c466fab'
          onSubmit={() => console.log('Submit!')}
          onReady={(form) => console.log('Form ready!')}
          loading={<div>Loading...</div>}
          />:<div dangerouslySetInnerHTML={{ __html: content }}></div>}
          
          {!!CTAButtonText && !!CTAButtonLink ? (
            <CTAbutton url={CTAButtonLink} style={{cursor:'pointer'}} classNames={['background_'+Gradient]} message={CTAButtonText} handler={(e) => clickHandler(e)} />
          ) : null}
        </div>
      </>
    );
  };

  const ContentFullWidth = () => {
    return (
      <>
        <div style={{width:'90%'}} className={styles.TextBlock}>
          <div dangerouslySetInnerHTML={{ __html: content.replace(/class="TextBlock"/g,`class=${styles.TextBlock}`).replace(/class="TextBlock"/g,`class=${styles.ImageBlock}`) }}></div>
        </div>
          {!!CTAButtonText && !!CTAButtonLink ? (
            <CTAbutton url={CTAButtonLink} style={{cursor:'pointer'}} classNames={['background_'+Gradient]} message={CTAButtonText} handler={(e) => clickHandler(e)} />
          ) : null}
      </>
    );
  };

  return( Title.toUpperCase()=='BANNER'? (
    <></>
   ):( 
    <section
      className={ContentBlockClasses.join('  ')}
      style={bannerBackground}
      id={SectionID}>
      {Background ? (
        <GatsbyImage
          image={getImage(Background.localFile)}
          className={styles.BackgroundImage}
        />
      ) : null}
      <div className={styles.ContentBlock} style={orderStyle}>
        {ContentOrder === 'ImageFirst' || ContentOrder === 'TextFirst' ? (
          <ContentWithImage />
        ) : ContentOrder === 'TextFullWidth' ? (
          <ContentFullWidth />
        ) : (
          <ContentNoImage />
        )}
      </div>
    </section>
  ));
};

export default ContentBlock;
