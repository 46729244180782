export const getBlocksToRender = (data) => {
    const blockArray = [];
    data.ContentBlocks && data.ContentBlocks.forEach(el => blockArray.push(el));
    data.ChecklistBlocks && data.ChecklistBlocks.forEach(el => blockArray.push(el));
    data.SubpageBlocks && data.SubpageBlocks.forEach(el => blockArray.push(el));
    const downloadSlider = [];
    data.downloadSlider && data.downloadSlider.forEach(el => downloadSlider.push({...el}));
    
    if(downloadSlider.length>0){
        blockArray.push({Items: [...downloadSlider], Type: 'downloadSlider', PageOrder: parseInt(data.DownloadSliderPageOrder)});
    }

    blockArray.sort((a, b) => {
        return a.PageOrder - b.PageOrder;
    });
    return blockArray;

};